<template>
    <div class="laborDispatch_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.recruitmentName"> </CourseTitle>
            <div class="content recruitWorkers">
                <div class="recruitWorkers_list flex" v-for="(item, index) in treeData" :key="index">
                    <div class="LeftSide">
                        <div class="label display nowrap">{{ item.label }}</div>
                    </div>
                    <div class="secondLevel flex flex-wrap">
                        <div class="secondLevel_list display pointer" :class="activeIndex == index && activeIndexSon == indexs ? 'active' : ''
                            " v-for="(items, indexs) in item.children" :key="indexs" @click="changeBtn(index, indexs)">
                            {{ items.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            activeIndex: null,
            activeIndexSon: null,
            treeData: [
                {
                    id: 1,
                    label: '特种作业',
                    secondLevel: [
                        {
                            label: '低压电工',
                        },
                        {
                            label: '焊工',
                        },
                        {
                            label: '高处安装',
                        },
                        {
                            label: '拆除作业 ',
                        },
                        {
                            label: '一级造价工程师',
                        },
                    ],
                },
                {
                    id: 2,
                    label: '施工现场专业人员',
                    secondLevel: [
                        {
                            label: '施工员',
                        },
                        {
                            label: '质量员',
                        },
                        {
                            label: '安全员',
                        },
                        {
                            label: '材料员 ',
                        },
                    ],
                },
            ],
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.laborDispatchApi().then((res) => {
                this.formData = res.data.info
                this.treeData = res.data.tree
            })
        },
        changeBtn(index, indexs) {
            this.activeIndex = index
            this.activeIndexSon = indexs
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.laborDispatch_page {
    height: 100%;
    background: #f4f5f5;
    padding-bottom: 72px;

    .module1 {
        min-height: 500px;

        .recruitWorkers {
            margin-top: 41px;

            .recruitWorkers_list {
                margin-bottom: 15px;

                .LeftSide {
                    min-width: 204px;
                    position: relative;

                    .label {
                        min-width: 140px;
                        height: 54px;
                        background: #003c7e;
                        padding: 0px 34px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .secondLevel {
                    margin-left: 24px;

                    .secondLevel_list {
                        height: 54px;
                        border: 1px solid #dddddd;
                        padding: 0px 34px;
                        margin-right: 15px;
                        margin-bottom: 15px;
                    }

                    .active {
                        border: 1px solid #003c7e;
                        color: #003c7e !important;
                    }
                }
            }
        }
    }
}
</style>